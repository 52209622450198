<template>
	<div>
		<header-component />

		<div class="container">
			<b-row>
				<b-col>
					<router-link to="/simulador">
						<img src="@/images/backgrounds/header-esportivo.png"
							class="img-fluid d-none d-md-block d-lg-block d-xl-block" />
						<img src="@/images/backgrounds/mobile/header-esportivo.png" class="img-fluid d-block d-md-none rounded" />
					</router-link>
				</b-col>
			</b-row>
		</div>
		<ProductDisplay />

		<div class="container-fluid">
			<b-row class="text-white text-center mt-5">
				<b-col md="6" offset-md="3">
					<h1 style="font-size: 24px; font-weight: 700;">Ação Camisetas</h1>
					<p class="p-5">
						Não haveria nome melhor para nossa empresa. Fundada em 1987, a Ação
						Camisetas foi a pioneira no RN em sublimação, proporcionando aos
						nossos clientes e parceiro, a possibilidade de personalizar suas
						camisetas da forma que realmente queriam. <br /><br />Evoluímos a cada pedido, a
						cada dia, ouvindo o desejo de nossos clientes e sempre investindo no
						que há de mais moderno no nosso seguimento. 30 anos depois, aqui
						estamos! Sempre prontos para atender você, com carinho, agilidade e
						qualidade. Seja bem vindo, seja re-bem vindo a nossa Ação!
					</p>
				</b-col>
			</b-row>
		</div>

		<footer-component />
	</div>
</template>

<script>
import HeaderComponent from "@/components/Header"
import FooterComponent from "@/components/Footer"
import ProductDisplay from "@/components/ProductDisplay"

export default {
	data() {
		return {
			title: "Sobre",
		}
	},
	methods: {
		getIdTshirt(name, index) {
			return name.substring(0, 3) + "-" + (index + 1)
		}
	},
	components: {
		HeaderComponent,
		FooterComponent,
		ProductDisplay
	}
}
</script>
